<template>
  <section class="invoice-preview-wrapper">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="appData === undefined">
      <h4 class="alert-heading">Error fetching {{ modulename }} details</h4>
      <div class="alert-body">
        <b-link class="alert-link" :to="{ name: GENAPP_APP_STORE_MODULE_NAME }">
          No {{ modulename }} details found
        </b-link>
      </div>
    </b-alert>
    <b-row v-if="appData" class="invoice-preview">
      <b-col cols="12" xl="9" md="8">
        <b-card no-body class="invoice-preview-card">
          <b-card-body class="d-print-block d-none invoice-padding pb-0">
            <div
              class="d-flex justify-content- flex-md-row flex-column invoice-spacing mt-0"
            >
              <div>
                <div class="logo-wrapper">
                  <logo style="height: 10vw; wipricing: 10vw" />
                  <h3 class="text-primary invoice-logo">FRP</h3>
                </div>
              </div>
            </div>
          </b-card-body>
          <b-card-body class="invoice-padding pb-0">
            <div>
              <table class="mt-2 table mt-xl-0 w-100 table-bordered">
                <tr>
                  <th class="pb-50 w-50">
                    <span class="font-weight-bold">{{
                      $t("fields.amgratuvatormr")
                    }}</span>
                  </th>
                  <td class="pb-50">
                    {{ appData.data.amgratuvatormr }}
                  </td>
                </tr>



                 <tr>
                    <th class="pb-50 w-50">
                      <span class="font-weight-bold">{{
                        $t("fields.givancommpledet")
                      }}</span>
                    </th>
                    <td class="pb-50">
                      {{ appData.data.givancommpledet }}
                    </td>
                  </tr>

              </table>
            </div>
          </b-card-body>
          <hr class="invoice-spacing" />
        </b-card>
        <DocumentList
          :moduleid="GENAPP_APP_STORE_MODULE_NAME"
          modulename="documents_guarantor"
          :reference_id="appData.id"
          :docuemnts="appData.documentlist"
        ></DocumentList>
      </b-col>
      <b-col cols="12" md="4" xl="3" class="invoice-actions">
        <viewsidelist
          :modulefield="GENAPP_APP_STORE_MODULE_NAME"
          :printview="printview"
          :id="appData.id"
          :checkdeletefun="checkdeletefun"
        ></viewsidelist>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import viewsidelist from "@/views/Component/viewsidelist.vue";
import {
  BAvatar,
  BRow,
  BCol,
  BAlert,
  BLink,
  BCardText,
  BCard,
  BTable,
  BTableLite,
  BCardBody,
  BForm,
  BButton,
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import DocumentList from "@/views/Component/DocumetsList/DocumentList.vue";

import Ripple from "vue-ripple-directive";
import StoreModule from "./StoreModule";
import useList from "./useList";
import { useToast } from "vue-toastification/composition";
import Logo from "@core/layouts/components/Logo.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BCol,
    BCard,
    BAvatar,
    viewsidelist,
    BRow,
    BCardBody,
    BCardText,
    BForm,
    BTable,
    BCardBody,
    BTableLite,
    BButton,
    ToastificationContent,
    BAlert,
    DocumentList,
    BLink,
    Logo,
  },
  directives: {
    Ripple,
  },
  data() {
    return {};
  },
  setup(props, { emit }) {
    const GENAPP_APP_STORE_MODULE_NAME = "documents-guarantor";
    const modulename = "Guarantor";
    const modulenamesub = "guarantor";
    if (!store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
      store.registerModule(GENAPP_APP_STORE_MODULE_NAME, StoreModule);
    onUnmounted(() => {
      if (store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
        store.unregisterModule(GENAPP_APP_STORE_MODULE_NAME);
    });
    const toast = useToast();
    const appData = ref(null);
    const serviceprov = ref(null);

    const printview = () => {
      window.print();
    };
    const { deletefun } = useList();
    store
      .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetchData", {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        if (!response.data) {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              text: "Couldnt find " + modulenamesub + " details",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          router
            .push({
              name: GENAPP_APP_STORE_MODULE_NAME,
            })
            .catch(() => {});
        }
        appData.value = response.data.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          appData.value = undefined;
          toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              text: "Couldnt find " + modulenamesub + " details",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } else if (error.response.status === 500) {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              text: "Input fields missing values",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } else if (error.response.status === 422) {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              text: "Please refresh your page",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }
        router
          .push({
            name: GENAPP_APP_STORE_MODULE_NAME,
          })
          .catch(() => {});
      });

    return {
      GENAPP_APP_STORE_MODULE_NAME,
      deletefun,
      modulename,
      modulenamesub,
      appData,
      printview,
      serviceprov,
    };
  },
  methods: {
    checkdeletefun(val) {
      var self = this;
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deletefun(val);
          this.$swal({
            icon: "success",
            title: "Deleted!",
            text: this.modulename + " has been deleted.",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          self.$router.push({
            name: this.GENAPP_APP_STORE_MODULE_NAME,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {
  a {
    color: black;
    text-decoration: none !important;
  }
  .d-print-block {
    display: block !important;
  }
  .content-header-right {
    display: none;
  }
  .invoice-actions {
    display: none;
  }
  .breadcrumbs-top {
    display: none;
  }
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  } // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-wipricing: 100%;
        flex-grow: 1;
      }
      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-wipricing: 50% !important;
            }
            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }
  }
}
</style>
