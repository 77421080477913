import {
  ref,
  watch,
  computed
} from '@vue/composition-api'
import store from '@/store'
import {
  title
} from '@core/utils/filter'

// Notification
import {
  useToast
} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useDatasList() {
  // Use toast
  const modulename = "guarantor";
  const modulenamesub = "Guarantor";
  const GENAPP_APP_STORE_MODULE_NAME = "documents-guarantor";
  const toast = useToast()
  const refDataListTable = ref(null)

  // Table Handlers
  const tableColumns = [{
      key: 'amgratuvatormr',
      label: 'Guarantor of Mr ',
      sortable: false
    },
    {
      key: 'givancommpledet',
      label: 'Give Complete Details',
      sortable: false
    },
    // {
    //   key: 'branch',
    //   label: 'Branch Name',
    //   sortable: false
    // },

    {
      key: 'actions',
      thClass: 'text-right',
      sortable: false
    },
  ]
  const perPage = ref(10)
  const totalDatas = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('created_at')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refDataListTable.value ? refDataListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalDatas.value,
    }
  })

  const refetchData = () => {
    if (refDataListTable.value) {
      refDataListTable.value.refresh()
    }
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  const fetchDatas = (ctx, callback) => {
    store
      .dispatch(GENAPP_APP_STORE_MODULE_NAME + '/fetchDatas', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value ? sortBy.value : 'created_at',
        sortDesc: isSortDirDesc.value ? 'desc' : 'asc',
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        const datas = response.data.data.data
        const total = response.data.data.meta.total

        callback(datas)
        totalDatas.value = total
      })
      .catch((error) => {
        console.log(error)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            text: "Couldnt find " + modulenamesub + " details",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deletefun = (ctx, callback) => {
    store
      .dispatch(GENAPP_APP_STORE_MODULE_NAME + '/deleteData', {
        id: ctx,
      })
      .then(response => {
        refetchData()

      })
      .catch((err) => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            text: "Couldnt delete " + modulenamesub + " details",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }



  // *=======================---*
  // *--------- UI ---------------------------------------*
  // *=======================---*

  const resolveDataRoleVariant = is_active => {
    if (is_active) return 'primary'
    else return 'danger'
  }

  return {
    fetchDatas,
    deletefun,
    tableColumns,
    perPage,
    currentPage,
    totalDatas,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refDataListTable,

    resolveDataRoleVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
